//
// ・tspotサイトへの誘導バナーの位置調整(tspotバナーを表示している場合)
// ・Cookie利用許諾Popup表示とその処理(GA4の有効化)
//
// 本ファイルの前に以下を<script>や<link>でインクルード済みであること
// 名前             サイト                                         CDNの例
// js-cookie        https://github.com/js-cookie/js-cookie        https://cdn.jsdelivr.net/npm/js-cookie@2/src/js.cookie.min.js
// cookie consent   https://www.osano.com/cookieconsent           ※1 https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js
//                                                                https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css
// ※1:オリジナルコードでは次の支障があったので改造したコードを使用。
// 1.iPadで許諾Popupが画面横幅を無視したFloatingで表示(横幅の半分程度の幅)されるため、モバイルデバイス判定を行わないように修正。
// 2.同意済みだと再設定（リボーク）が行えななかったので、常に再設定を行えるように修正。
//
{
  let _strGA4Domain = null;

  window.ox = window.ox || {};

  //////////////////////////////////////////////////////
  // Cookei許諾POPUPのElement取得
  ox.getCookieConsent = () => {
    return document.querySelector('div[aria-label="cookieconsent"]');;
  }

  //////////////////////////////////////////////////////
  ox.elmBanners = document.getElementsByClassName('fixBanner');
  ox.elmCookieConsent = ox.getCookieConsent();

  //////////////////////////////////////////////////////
  // TSPOTバナー位置を調整します。
  // cookie許諾POPUPが表示されているならその上に、表示されていないなら画面下部にバナーを移動します。
  ox.moveBanner = () => {
    const dateWork = new Date();
    if( ox.elmCookieConsent && (ox.elmBanners.length > 0) ){
      let a = ox.elmCookieConsent.getBoundingClientRect();
      if( ox.elmCookieConsent.style.display === 'none' ){
        ox.elmBanners[0].style.bottom = "20px";
        //console.log( `移動(無): ${dateWork.toTimeString()} : ${ox.elmCookieConsent.style.display}` );
        return false;
      }
      else{
        ox.elmBanners[0].style.bottom = a.height + 5 + "px";
        //console.log( `移動(有): ${dateWork.toTimeString()} : ${ox.elmCookieConsent.style.display}` );
        return true;
      }
    }
  }

  //////////////////////////////////////////////////////
  // cookie許諾POPUPの表示状態が変化したときの処理
  // バナー位置を調整し、cookie許諾POPUPが非表示になったのならcookie許諾POPUPの監視を終えます。
  ox.observer = new MutationObserver( (records) => {
    // 変化が発生したときの処理を記述
    const dateWork = new Date();
    //console.log( `変化検出: ${dateWork.toTimeString()} : ${ox.elmCookieConsent.style.display}` );
    if( !ox.moveBanner() ){
      // cookie許諾POPUPが非表示になったら監視終了 → Cookie許諾をrevokeするとCookie許諾Popupを再表示するので監視は継続します。
      // ox.observer.disconnect();
    }
  });

  //////////////////////////////////////////////////////
  // リサイズやcookie許諾POPUPの表示状態の監視を開始します。
  ox.startWatch = () => {

    //ox.elmCookieConsent.style.zIndex = 1000;

    // cookie許諾POPUPの表示変化の監視を開始します。
    ox.observer.observe( ox.elmCookieConsent, {
      attributes: true,
      attributeFilter: ["style"]
    });

    // リサイズ監視
    window.addEventListener("resize", () => {
      let dateWork = new Date();
      //console.log(`リサイズ：${dateWork.toTimeString()}`);
      ox.moveBanner();
    });

    // 初回位置調整
    ox.moveBanner();
  };

  // cookie許諾POPUPが作成されたときの処理
  // リサイズやcookie許諾POPUPの表示状態の監視を始めます。
  ox.obsFindLFPopup = new MutationObserver( (r)=>{
    const dateWork = new Date();
    ox.elmCookieConsent = ox.getCookieConsent();
    if( ox.elmCookieConsent ){
      // cookie許諾POPUPを検出したのでbodyの監視を終えます。
      ox.obsFindLFPopup.disconnect();
      console.log("---------------------- 検出" + dateWork.toTimeString() );
      ox.startWatch();
    }
  });

  if( !ox.elmCookieConsent ){
    // cookie許諾POPUPがまだ生成されていないなら生成されるのを監視します。
    ox.obsFindLFPopup.observe( document.getElementsByTagName("body")[0], {
      childList: true
    });
  }
  else{
    // cookie許諾POPUPが生成済みなら、画面サイズやcookie許諾POPUPの表示状態変化を監視します。
    ox.startWatch();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Cookie consent初期化処理
  //
  const strCookieName = "cookieconsent_o";
  const strAllow = window.cookieconsent.status.allow;

  //////////////////////////////////////////////////////
  //
  // bEnable    Cookie許諾ならtrueを指定
  // strGA4ID   GA4のIDを指定
  //
  ox.EnableGA4 = ( bEnable, strGA4ID ) => {

    const strTAG = "ga-disable-" + strGA4ID;

    if( bEnable ){
      console.log("consent granted");
      window[strTAG] = false;
      gtag("consent", "update", {
        analytics_storage: "granted",
        wait_for_update: 500
      });
    }
    else{
      console.log("consent denied");
      gtag("consent", "update", {
        analytics_storage: "denied",
        wait_for_update: 500
      });
      window[strTAG] = true;

      Object.keys( Cookies.get() ).forEach( (strCookieName) => {
        if( strCookieName.indexOf("_") === 0 ){
          console.log(strCookieName);
          if( _strGA4Domain ){
            Cookies.remove( strCookieName, { domain: _strGA4Domain } );
          }
          else{
            Cookies.remove( strCookieName );
          }
        }
      });
    }
  }

  //////////////////////////////////////////////////////
  //
  //  strGA4DomainName  GA4がCookie設定に用いているドメイン名
  //  strGA4ID          GA4のタグID
  //
  ox.InitCookieConset = ( strGA4DomainName, strGA4ID) => {

    _strGA4Domain = strGA4DomainName;

    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: "rgba( 239, 239, 239, 0.9 )",
          text: "#303030"
        },
        button: {
          background: "#8ec760",
          text: "#ffffff"
        }
      },
      position: "bottom",
      type: "opt-in",
      content: {
        message: "このWebサイトはサービス向上、広告、宣伝及び営業等の目的からCookieを使用しています。同意すると閲覧履歴とお客様より提供いただいた情報を紐付けて分析に利用する場合があります。",
        deny: "拒否",
        allow: "同意",
        link: "[詳細]",
        href: "https://oxfordimmunotec.co.jp/privacypolicy/",
        policy: "Cookie再設定"
      },
      cookie : {
        name: strCookieName,
        domain: "",     // Cookieのドメイン名を指定すると、そのサブドメインでもCookieが送信されるので指定しない（この他、ドメイン名の先頭に.が付与された同名のクッキーも何故か作成されるのを回避）
        expiryDays: 1,
      },
      revokable: true,
      onRevokeChoice : () => {
        // リボーク時は再度「同意」か「拒否」が選択されるまで「拒否」と見なす
        ox.EnableGA4( false, strGA4ID );
      },
      onInitialise: () => {
        ox.EnableGA4( strAllow === Cookies.get( strCookieName ), strGA4ID );
      },
      onStatusChange: (status, chosenBefore) => {
        console.log( `consent status = ${status}` );
        if( strAllow === status ){
          // 同意する
          ox.EnableGA4( true, strGA4ID );
          // Cookieconsentのcookieの期限を延長(1日→1年)
          Cookies.set( strCookieName, strAllow, { expires: 365 })
        }
        else{
          // 同意しない
          ox.EnableGA4( false, strGA4ID );
        }
      }
    });
  }

}
